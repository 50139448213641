import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import bcrypt from 'bcryptjs';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { firestore } from '../firebaseConfig';
import './Home.css';
import Navbar from './Navbar';


export default function Home() {
  const [domainName, setDomainName] = useState('');
  const [keepPassword, setKeepPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [expirationTime, setExpirationTime] = useState('24 hrs');
  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleTogglePassword = () => {
    setKeepPassword((prev) => !prev);
    setPassword('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleExpirationTimeChange = (event) => {
    setExpirationTime(event.target.textContent);
    setIsTimeSelected(true);
  };

  const calculateExpirationTimestamp = () => {
    const now = new Date();
    switch (expirationTime) {
      case '1 hr':
        return now.setHours(now.getHours() + 1);
      case '3 hrs':
        return now.setHours(now.getHours() + 3);
      case '5 hrs':
        return now.setHours(now.getHours() + 5);
      case '10 hrs':
        return now.setHours(now.getHours() + 10);
      case '24 hrs':
        return now.setDate(now.getDate() + 1);
      case '2 days':
        return now.setDate(now.getDate() + 2);
      case '4 days':
        return now.setDate(now.getDate() + 4);
      case '7 days':
        return now.setDate(now.getDate() + 7);
      default:
        return now.setDate(now.getDate() + 1);
    }
  };


  const handleCopyClick = () => {
    if (domainName) {
      const fullURL = `nologin.in/${domainName}`;
      navigator.clipboard.writeText(fullURL).then(() => {
        toast.success('URL copied to clipboard!');
      }).catch((err) => {
        console.error('Could not copy text: ', err);
        toast.error('Failed to copy URL.');
      });
    } else {
      toast.error('Please enter a domain name to copy the URL.');
    }
  };

  const handleSubmit = async () => {
    if (!domainName.trim()) {
      toast.error('Domain name cannot be empty.');
      return;
    }
    try {
      const docRef = doc(firestore, 'documents', domainName);
      const docSnap = await getDoc(docRef);

      const currentTime = new Date().getTime();
      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log(data.expirationTimestamp);
        console.log(currentTime);
        if (data.expirationTimestamp && data.expirationTimestamp <= currentTime) {
          await setDoc(docRef, { ...data, content: '', password: '', passwordSet: false });
          toast.warn('Content expired and cleared.');
        }

        if (keepPassword && password === '') {
          toast.error('Enter a valid password');
          return;
        }

        if (!expirationTime) {
          toast.error('Set an expiration time!');
          return;
        }

        const expirationTimestamp = calculateExpirationTimestamp();

        if (keepPassword && !data.passwordSet) {
          const hashedPassword = await bcrypt.hash(password, 10);
          await setDoc(docRef, {
            ...data,
            passwordSet: keepPassword,
            password: hashedPassword,
            expirationTimestamp,
          });
        }
      } else {
        const expirationTimestamp = calculateExpirationTimestamp();
        const hashedPassword = keepPassword ? await bcrypt.hash(password, 10) : '';
        await setDoc(docRef, {
          passwordSet: keepPassword,
          password: hashedPassword,
          expirationTimestamp,
          content: '',
        });
      }

      navigate(`/${domainName}`);
    } catch (error) {
      console.error('Error setting document:', error);
      toast.error('Error setting document settings.');
    }
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div className='home-container'>

      <ToastContainer />

      <Navbar />

      <h1 className="subtitle">
        <span className="purple">Write, Upload, Save</span>
        <span className="orange"> & Share</span> <br />
        <span className="White">No Login Required!</span>
      </h1>

      <div className="form-group">
        <h5 htmlFor="domainNameInput" className="form-label">“Upload <span className="orange">Files, Texts, Codes</span> once , access on any device  using  domain name”</h5>
        <div className="input-group">
          <span className="input-group-text">nologin.in/</span>
          <input
            type="text"
            className="form-control"
            id="domainNameInput"
            placeholder="Enter domain name"
            value={domainName}
            onChange={(e) => setDomainName(e.target.value.toLowerCase())}
            onKeyDown={handleKeyPress}
          />
          <div className='copy-div' onClick={handleCopyClick}>
            <ContentCopyIcon />
          </div>
        </div>
      </div>

      <div className="button-container">
        <div className="btnctn1">
          <div className="icon-container">
            <span className="tooltip-text-Set-Time">Choose how long your notes will be accessible on this link.</span>
            <i className="info-icon">
              <span className="material-icons">info</span>
            </i>
          </div>
          <div className="dropdown-group">
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle button-set" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                Set Time
              </button>
              <ul className="dropdown-menu">
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>1 hr</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>3 hrs</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>5 hrs</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>10 hrs</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>24 hrs</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>2 days</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>4 days</button></li>
                <li><button className="dropdown-item" type="button" onClick={handleExpirationTimeChange}>7 days</button></li>
              </ul>
            </div>
            <span className="selected-value">
              {isTimeSelected ? `Selected Time: ${expirationTime}` : 'Default Time: 24 hrs'}
            </span>
          </div>
        </div>
        <div className="btnctn2">
          <div className="icon-container">
            <span className="tooltip-text-Set-Password">Set a password to prevent others from editing your text.</span>
            <i className="info-icon">
              <span className="material-icons">info</span>
            </i>
          </div>
          <div className="toggle-group">
            <button onClick={handleTogglePassword} className="btn btn-secondary button-set">
              {keepPassword ? 'Remove Password' : 'Set Password'}
            </button>
          </div>
        </div>
      </div>

      {keepPassword && (
        <div className="form-group">
          <label htmlFor="passwordInput">Password</label>
          <div className="password-input-group">
            <input
              type={showPassword ? 'text' : 'password'}
              className="form-control"
              id="passwordInput"
              placeholder="Enter password"
              value={password}
              onChange={handlePasswordChange}
              onKeyDown={handleKeyPress}
            />
            <button
              type="button"
              className="btn btn-outline-secondary pass-btn"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </button>
          </div>
        </div>
      )}

      <button onClick={handleSubmit} className="btn btn-primary submit-button">
        SUBMIT
        <span className="arrow-container">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="arrow-icon"
            viewBox="0 0 24 24"
            fill="none"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="4" y1="12" x2="20" y2="12" />
            <polyline points="14 6 20 12 14 18" />
          </svg>
        </span>
      </button>

    </div>
  );
}
